



import { Component, Mixins } from 'vue-property-decorator'
import Collect from '@/components/organisms/study/Collect.vue'
import Contents1 from '@/views/student/study/Contents1.vue'

@Component({
  components: {
    Collect,
  },
})
export default class Collect9 extends Mixins(Contents1) {}
